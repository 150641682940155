import React, { useEffect } from "react";
import Hero from "@components/shared/hero";
import FeatureColumn from "@components/pages-components/features/featureColumn";
import ContactFormWithTitle from "@components/forms/contact-form-with-title";
import TeamBreif from "@components/pages-components/about-us/teamBreif";
import SEO from "@components/shared/seo";
import LayOut from "@components/layout";
import { graphql, PageProps } from "gatsby";
import {
  Query,
  SanityAboutUsPageConnection,
  SanityVideoBlock,
} from "@graphql-types";
import { useRegionChanged } from "@util/hooks";
import VideoBlock from "@components/shared/sub/videoBlock";

interface Data extends Query {
  altRegions: SanityAboutUsPageConnection;
}

interface Props extends PageProps {
  data: Data;
  pageContext: any;
}

const AboutUs = (props: Props) => {
  const sanityAboutUsPage = props.data.allSanityAboutUsPage.nodes[0];
  const alternateRegions = props.data.altRegions.nodes.map(
    alt => alt._rawRegion
  );

  useRegionChanged("about");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!sanityAboutUsPage) return null;

  return (
    <>
      <SEO
        seoData={sanityAboutUsPage.seo}
        alternateRegions={alternateRegions}
        slug={props.pageContext.slug}
      />

      <LayOut>
        {sanityAboutUsPage?.hero && (
          <Hero heroContent={sanityAboutUsPage?.hero} />
        )}
        {sanityAboutUsPage.featureColumnsTop &&
          sanityAboutUsPage.featureColumnsTop.map(feature => {
            if (feature?._type === "videoBlock") {
              let videoFeature = feature as SanityVideoBlock;
              return (
                <VideoBlock
                  title={videoFeature.videoHeader}
                  description={videoFeature.videoDescription}
                  videoUrl={videoFeature.videoUrl}
                />
              );
            }
            return (
              <FeatureColumn column={feature} noBlueBackground noMinHeight />
            );
          })}
        {sanityAboutUsPage.teamBrief && (
          <TeamBreif teamBrief={sanityAboutUsPage.teamBrief} />
        )}

        {sanityAboutUsPage.belowTeamBlock && (
          <FeatureColumn column={sanityAboutUsPage.belowTeamBlock} />
        )}

        <ContactFormWithTitle />
      </LayOut>
    </>
  );
};

export default AboutUs;

export const query = graphql`
  query aboutQuery($iban: String) {
    allSanityAboutUsPage(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        hero {
          ...sanityHero
        }
        seo {
          ...sanitySeo
        }
        featureColumnsTop {
          __typename
          ...sanityImageTextColumn
          ... on SanityVideoBlock {
            _type
            videoHeader
            videoDescription
            videoUrl
            videoThumbnail {
              ...sanityImageWithMeta
            }
          }
        }
        complianceLogos {
          ...sanityAccreditedSliderBlock
        }
        belowTeamBlock {
          ...sanityImageTextColumn
        }
        teamBrief {
          title
          celoTeamHeader {
            _rawContent
          }
          teamCatogories {
            _key
            title
            description
            image {
              altText

              asset {
                gatsbyImageData(layout: CONSTRAINED, width: 250)
              }
            }
          }
          button {
            ...sanityLink
          }
        }
      }
    }
    altRegions: allSanityAboutUsPage {
      nodes {
        _rawRegion(resolveReferences: { maxDepth: 10 })
      }
    }
  }
`;
